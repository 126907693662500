@import url(~ag-grid-community/dist/styles/ag-grid.css);
@import url(~ag-grid-community/dist/styles/ag-theme-balham.css);
@import url(~@swimlane/ngx-datatable/index.css);
/*
  This stylesheet uses scss valiables for most of the colors / background-colors of the table
  to enable the customization of the displayed table without cloning the stylesheet into the
  own application.

  To modify table colors, add the following lines to the scss file of your application
  (this example modifies the color of the selected row - selectionType = single, multi or multiClick):

  $ngx-datatable-selected-active-background: yellow;
  $ngx-datatable-selected-active-background-hover: rgba(yellow, 0.2);

  @import '~@swimlane/ngx-datatable/index.css';
  @import '~@swimlane/ngx-datatable/themes/material.scss';
  @import '~@swimlane/ngx-datatable/assets/icons.css';

That's all.
*/
@import url(~@swimlane/ngx-datatable/assets/icons.css);
.ngx-datatable.material {
  background: #fff;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  /**
	 * Shared Styles
	 */
  /**
	 * Global Row Styles
	 */
  /**
	 * Header Styles
	 */
  /**
	 * Body Styles
	 */
  /**
	 * Footer Styles
	 */ }
  .ngx-datatable.material.striped .datatable-row-odd {
    background: #eee; }
  .ngx-datatable.material.single-selection .datatable-body-row.active,
  .ngx-datatable.material.single-selection .datatable-body-row.active .datatable-row-group, .ngx-datatable.material.multi-selection .datatable-body-row.active,
  .ngx-datatable.material.multi-selection .datatable-body-row.active .datatable-row-group, .ngx-datatable.material.multi-click-selection .datatable-body-row.active,
  .ngx-datatable.material.multi-click-selection .datatable-body-row.active .datatable-row-group {
    background-color: #304ffe;
    color: #fff; }
  .ngx-datatable.material.single-selection .datatable-body-row.active:hover,
  .ngx-datatable.material.single-selection .datatable-body-row.active:hover .datatable-row-group, .ngx-datatable.material.multi-selection .datatable-body-row.active:hover,
  .ngx-datatable.material.multi-selection .datatable-body-row.active:hover .datatable-row-group, .ngx-datatable.material.multi-click-selection .datatable-body-row.active:hover,
  .ngx-datatable.material.multi-click-selection .datatable-body-row.active:hover .datatable-row-group {
    background-color: #193ae4;
    color: #fff; }
  .ngx-datatable.material.single-selection .datatable-body-row.active:focus,
  .ngx-datatable.material.single-selection .datatable-body-row.active:focus .datatable-row-group, .ngx-datatable.material.multi-selection .datatable-body-row.active:focus,
  .ngx-datatable.material.multi-selection .datatable-body-row.active:focus .datatable-row-group, .ngx-datatable.material.multi-click-selection .datatable-body-row.active:focus,
  .ngx-datatable.material.multi-click-selection .datatable-body-row.active:focus .datatable-row-group {
    background-color: #2041ef;
    color: #fff; }
  .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover,
  .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
    background-color: #eee;
    transition-property: background;
    transition-duration: 0.3s;
    transition-timing-function: linear; }
  .ngx-datatable.material:not(.cell-selection) .datatable-body-row:focus,
  .ngx-datatable.material:not(.cell-selection) .datatable-body-row:focus .datatable-row-group {
    background-color: #ddd; }
  .ngx-datatable.material.cell-selection .datatable-body-cell:hover,
  .ngx-datatable.material.cell-selection .datatable-body-cell:hover .datatable-row-group {
    background-color: #eee;
    transition-property: background;
    transition-duration: 0.3s;
    transition-timing-function: linear; }
  .ngx-datatable.material.cell-selection .datatable-body-cell:focus,
  .ngx-datatable.material.cell-selection .datatable-body-cell:focus .datatable-row-group {
    background-color: #ddd; }
  .ngx-datatable.material.cell-selection .datatable-body-cell.active,
  .ngx-datatable.material.cell-selection .datatable-body-cell.active .datatable-row-group {
    background-color: #304ffe;
    color: #fff; }
  .ngx-datatable.material.cell-selection .datatable-body-cell.active:hover,
  .ngx-datatable.material.cell-selection .datatable-body-cell.active:hover .datatable-row-group {
    background-color: #193ae4;
    color: #fff; }
  .ngx-datatable.material.cell-selection .datatable-body-cell.active:focus,
  .ngx-datatable.material.cell-selection .datatable-body-cell.active:focus .datatable-row-group {
    background-color: #2041ef;
    color: #fff; }
  .ngx-datatable.material .empty-row {
    height: 50px;
    text-align: left;
    padding: 0.5rem 1.2rem;
    vertical-align: top;
    border-top: 0; }
  .ngx-datatable.material .loading-row {
    text-align: left;
    padding: 0.5rem 1.2rem;
    vertical-align: top;
    border-top: 0; }
  .ngx-datatable.material .datatable-header .datatable-row-left,
  .ngx-datatable.material .datatable-body .datatable-row-left {
    background-color: #fff;
    background-position: 100% 0;
    background-repeat: repeat-y;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAABCAYAAAD5PA/NAAAAFklEQVQIHWPSkNeSBmJhTQVtbiDNCgASagIIuJX8OgAAAABJRU5ErkJggg==); }
  .ngx-datatable.material .datatable-header .datatable-row-right,
  .ngx-datatable.material .datatable-body .datatable-row-right {
    background-position: 0 0;
    background-color: #fff;
    background-repeat: repeat-y;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAABCAYAAAD5PA/NAAAAFklEQVQI12PQkNdi1VTQ5gbSwkAsDQARLAIGtOSFUAAAAABJRU5ErkJggg==); }
  .ngx-datatable.material .datatable-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
    .ngx-datatable.material .datatable-header .datatable-header-cell {
      text-align: left;
      padding: 0.9rem 1.2rem;
      font-weight: 400;
      background-color: #fff;
      color: rgba(0, 0, 0, 0.54);
      vertical-align: bottom;
      font-size: 12px;
      font-weight: 500; }
      .ngx-datatable.material .datatable-header .datatable-header-cell .datatable-header-cell-wrapper {
        position: relative; }
      .ngx-datatable.material .datatable-header .datatable-header-cell.longpress .draggable::after {
        transition: transform 400ms ease, opacity 400ms ease;
        opacity: 0.5;
        transform: scale(1); }
      .ngx-datatable.material .datatable-header .datatable-header-cell .draggable::after {
        content: ' ';
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -30px 0 0 -30px;
        height: 60px;
        width: 60px;
        background: #eee;
        border-radius: 100%;
        opacity: 1;
        filter: none;
        transform: scale(0);
        z-index: 9999;
        pointer-events: none; }
      .ngx-datatable.material .datatable-header .datatable-header-cell.dragging .resize-handle {
        border-right: none; }
    .ngx-datatable.material .datatable-header .resize-handle {
      border-right: solid 1px #eee; }
  .ngx-datatable.material .datatable-body .datatable-row-detail {
    background: #f5f5f5;
    padding: 10px; }
  .ngx-datatable.material .datatable-body .datatable-group-header {
    background: #f5f5f5;
    border-bottom: solid 1px #d9d8d9;
    border-top: solid 1px #d9d8d9; }
  .ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell {
    text-align: left;
    padding: 0.9rem 1.2rem;
    vertical-align: top;
    border-top: 0;
    color: rgba(0, 0, 0, 0.87);
    transition: width 0.3s ease;
    font-size: 14px;
    font-weight: 400; }
  .ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-group-cell {
    text-align: left;
    padding: 0.9rem 1.2rem;
    vertical-align: top;
    border-top: 0;
    color: rgba(0, 0, 0, 0.87);
    transition: width 0.3s ease;
    font-size: 14px;
    font-weight: 400; }
  .ngx-datatable.material .datatable-body .progress-linear {
    display: block;
    position: relative;
    width: 100%;
    height: 5px;
    padding: 0;
    margin: 0;
    position: absolute; }
    .ngx-datatable.material .datatable-body .progress-linear .container {
      display: block;
      position: relative;
      overflow: hidden;
      width: 100%;
      height: 5px;
      -webkit-transform: translate(0, 0) scale(1, 1);
      transform: translate(0, 0) scale(1, 1);
      background-color: #aad1f9; }
      .ngx-datatable.material .datatable-body .progress-linear .container .bar {
        transition: all 0.2s linear;
        -webkit-animation: query 0.8s infinite cubic-bezier(0.39, 0.575, 0.565, 1);
        animation: query 0.8s infinite cubic-bezier(0.39, 0.575, 0.565, 1);
        transition: -webkit-transform 0.2s linear;
        transition: transform 0.2s linear;
        background-color: #106cc8;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 5px; }
  .ngx-datatable.material .datatable-footer {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    font-size: 12px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.54); }
    .ngx-datatable.material .datatable-footer .page-count {
      line-height: 50px;
      height: 50px;
      padding: 0 1.2rem; }
    .ngx-datatable.material .datatable-footer .datatable-pager {
      margin: 0 10px; }
      .ngx-datatable.material .datatable-footer .datatable-pager li {
        vertical-align: middle; }
        .ngx-datatable.material .datatable-footer .datatable-pager li.disabled a {
          color: rgba(0, 0, 0, 0.26) !important;
          background-color: transparent !important; }
        .ngx-datatable.material .datatable-footer .datatable-pager li.active a {
          background-color: rgba(158, 158, 158, 0.2);
          font-weight: bold; }
      .ngx-datatable.material .datatable-footer .datatable-pager a {
        height: 22px;
        min-width: 24px;
        line-height: 22px;
        padding: 0 6px;
        border-radius: 3px;
        margin: 6px 3px;
        text-align: center;
        vertical-align: top;
        color: rgba(0, 0, 0, 0.54);
        text-decoration: none;
        vertical-align: bottom; }
        .ngx-datatable.material .datatable-footer .datatable-pager a:hover {
          color: rgba(0, 0, 0, 0.75);
          background-color: rgba(158, 158, 158, 0.2); }
      .ngx-datatable.material .datatable-footer .datatable-pager .datatable-icon-left,
      .ngx-datatable.material .datatable-footer .datatable-pager .datatable-icon-skip,
      .ngx-datatable.material .datatable-footer .datatable-pager .datatable-icon-right,
      .ngx-datatable.material .datatable-footer .datatable-pager .datatable-icon-prev {
        font-size: 20px;
        line-height: 20px;
        padding: 0 3px; }
  .ngx-datatable.material .datatable-summary-row .datatable-body-row {
    background-color: #ddd; }
    .ngx-datatable.material .datatable-summary-row .datatable-body-row:hover {
      background-color: #ddd; }
    .ngx-datatable.material .datatable-summary-row .datatable-body-row .datatable-body-cell {
      font-weight: bold; }

/**
 * Checkboxes
**/
.datatable-checkbox {
  position: relative;
  margin: 0;
  cursor: pointer;
  vertical-align: middle;
  display: inline-block;
  box-sizing: border-box;
  padding: 0; }
  .datatable-checkbox input[type='checkbox'] {
    position: relative;
    margin: 0 1rem 0 0;
    cursor: pointer;
    outline: none; }
    .datatable-checkbox input[type='checkbox']:before {
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
      content: '';
      position: absolute;
      left: 0;
      z-index: 1;
      width: 1rem;
      height: 1rem;
      border: 2px solid #f2f2f2; }
    .datatable-checkbox input[type='checkbox']:checked:before {
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      transform: rotate(-45deg);
      height: 0.5rem;
      border-color: #009688;
      border-top-style: none;
      border-right-style: none; }
    .datatable-checkbox input[type='checkbox']:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 1rem;
      height: 1rem;
      background: #fff;
      cursor: pointer; }

/**
 * Progress bar animations
 */
@keyframes query {
  0% {
    opacity: 1;
    transform: translateX(35%) scale(0.3, 1); }
  100% {
    opacity: 0;
    transform: translateX(-50%) scale(0, 1); } }

/* You can add global styles to this file, and also import other style files */
@font-face {
  font-family: 'thsarabunPSK';
  src: local("thsarabunPSK"), url("/assets/styles/app/globals/fonts/thsarabunPSK/THSarabun.ttf") format("truetype");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'THSarabunNew';
  src: url("/assets/styles/app/globals/fonts/thsarabunNew/thsarabunnew-webfont.eot");
  src: url("/assets/styles/app/globals/fonts/thsarabunNew/thsarabunnew-webfont.eot?#iefix") format("embedded-opentype"), url("/assets/styles/app/globals/fonts/thsarabunNew/thsarabunnew-webfont.woff") format("woff"), url("/assets/styles/app/globals/fonts/thsarabunNew/thsarabunnew-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'THSarabunNew';
  src: url("/assets/styles/app/globals/fonts/thsarabunNew/thsarabunnew_bolditalic-webfont.eot");
  src: url("/assets/styles/app/globals/fonts/thsarabunNew/thsarabunnew_bolditalic-webfont.eot?#iefix") format("embedded-opentype"), url("/assets/styles/app/globals/fonts/thsarabunNew/thsarabunnew_bolditalic-webfont.woff") format("woff"), url("/assets/styles/app/globals/fonts/thsarabunNew/thsarabunnew_bolditalic-webfont.ttf") format("truetype");
  font-weight: bold;
  font-style: italic; }

@font-face {
  font-family: 'THSarabunNew';
  src: url("/assets/styles/app/globals/fonts/thsarabunNew/thsarabunnew_italic-webfont.eot");
  src: url("/assets/styles/app/globals/fonts/thsarabunNew/thsarabunnew_italic-webfont.eot?#iefix") format("embedded-opentype"), url("/assets/styles/app/globals/fonts/thsarabunNew/thsarabunnew_italic-webfont.woff") format("woff"), url("/assets/styles/app/globals/fonts/thsarabunNew/thsarabunnew_italic-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: italic; }

@font-face {
  font-family: 'THSarabunNew';
  src: url("/assets/styles/app/globals/fonts/thsarabunNew/thsarabunnew_bold-webfont.eot");
  src: url("/assets/styles/app/globals/fonts/thsarabunNew/thsarabunnew_bold-webfont.eot?#iefix") format("embedded-opentype"), url("/assets/styles/app/globals/fonts/thsarabunNew/thsarabunnew_bold-webfont.woff") format("woff"), url("/assets/styles/app/globals/fonts/thsarabunNew/thsarabunnew_bold-webfont.ttf") format("truetype");
  font-weight: bold;
  font-style: normal; }

/*Sarabun*/
@font-face {
  font-family: 'Sarabun';
  src: url("/assets/styles/app/globals/fonts/sarabun/Sarabun-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Sarabun';
  src: url("/assets/styles/app/globals/fonts/sarabun/Sarabun-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic; }

@font-face {
  font-family: 'Sarabun';
  src: url("/assets/styles/app/globals/fonts/sarabun/Sarabun-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic; }

@font-face {
  font-family: 'Sarabun';
  src: url("/assets/styles/app/globals/fonts/sarabun/Sarabun-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal; }

/*end Sarabun*/
/* 
  @font-face {
    font-family: 'iconsmind';
    src:  url('iconsmind.eot?6kwbbs');
    src:  url('iconsmind.eot?6kwbbs#iefix') format('embedded-opentype'),
      url('iconsmind.ttf?6kwbbs') format('truetype'),
      url('iconsmind.woff?6kwbbs') format('woff'),
      url('iconsmind.svg?6kwbbs#iconsmind') format('svg');
    font-weight: normal;
    font-style: normal;
  } */
html, body {
  height: 100%; }

body {
  margin: 0;
  font-family: 'Sarabun';
  font-weight: bold;
  font-size: 16px; }

/* body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; } */
h1 {
  font-size: 22px !important; }

.fa, .fas {
  font-family: "Font Awesome 5 Free" !important;
  font-weight: 900; }

/* .item-name{
  font-size: 20px!important;
} */
.item-name {
  font-size: 15px !important; }

.mat-cell {
  font-size: 20px !important; }

.mat-header-cell {
  font-size: 20px !important; }

.mat-card-content {
  font-size: 16px !important; }

.mat-card-header .mat-card-title {
  font-size: 20px; }

.mat-card-subtitle, .mat-card-content {
  font-size: 20px; }

/* .text-size1{
  font-weight: bold;
  font-size: 20px;
}

.text-size2{
  font-weight: bold;
  font-size: 22px;
}

.text-size3{
  font-weight: bold;
  font-size: 24px;
}

.text-size4{
  font-weight: bold;
  font-size: 26px;
}

.text-size5{
  font-weight: bold;
  font-size: 32px;
} */
.blue-text {
  font-weight: bold;
  color: #0084b4 !important; }

.black-text {
  font-weight: bold;
  color: black !important; }

.grey-text {
  color: #70657b !important; }

.grey-dark-text {
  color: #777575 !important; }

.ag-root-wrapper {
  font-family: "Sarabun" !important;
  font-size: 20px; }

.ag-theme-balham .ag-header {
  font-family: "Sarabun" !important;
  font-size: 20px; }

.ag-header-container {
  background-color: #0084B4; }

/* 
.ag-theme-balham .ag-row {
  font-size: 20px;
  border-color: #d9dcde;
} */
mdb-select.mdb-select-outline .form-control {
  padding: 4px 12px; }

.layout-sidebar-compact .sidebar-left-secondary {
  padding: 0px !important;
  margin-bottom: 0px !important; }

.card-search {
  padding-bottom: 0px;
  border-radius: 10px 10px 0px 0px !important;
  border-top: 1px solid #0084B4 !important;
  border-left: 1px solid #0084B4 !important;
  border-right: 1px solid #0084B4 !important; }

.card-table {
  font-size: 20px;
  border-radius: 0 0 10px 10px !important;
  border-left: 1px solid #0084B4;
  border-right: 1px solid #0084B4;
  border-bottom: 1px solid #0084B4; }

.iconhome {
  font-size: 52px; }

.menulayout {
  padding-right: 10px;
  padding-left: 10px; }

.menueach {
  background-color: floralwhite;
  margin-bottom: 1%; }

.textDesc {
  line-height: 0.6 !important; }

.textgroup {
  line-height: 1; }

.datatable-header {
  background: #0084B4; }

.ngx-datatable.material .datatable-header .datatable-header-cell {
  color: white;
  vertical-align: bottom;
  font-size: 16px;
  font-weight: bold; }

.ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell {
  font-weight: bold;
  font-size: 16px;
  padding: .1rem 1rem;
  color: #70657b !important; }

.ngx-datatable.material .datatable-footer {
  font-size: 13px; }

.search-btn {
  font-size: 16px; }

.primay-btn {
  font-size: 16px; }

.edit-btn {
  color: white; }

.btn-warning:hover {
  color: white; }

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button, .mat-flat-button, .mat-fab, .mat-mini-fab {
  font-size: 20px; }

.btn-light {
  color: white; }

::ng-deep datatable-footer {
  /* border-top: 1px solid #e6e9ee; */
  /* border-left: 1px solid #ccd0d9; */
  min-height: 20px;
  position: absolute;
  bottom: 0;
  z-index: 10;
  background: #fff;
  min-height: 42px; }

::ng-deep datatable-body {
  width: 100%;
  height: calc(100vh - 325px - 15px) !important; }

::ng-deep datatable-body.datatable-body {
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 42px;
  border-right: 1px solid #ccd0d9;
  border-left: 1px solid #ccd0d9;
  padding-bottom: 50px; }

::ng-deep .action-group {
  text-align: center !important; }

.text-size1 {
  font-weight: bold;
  font-size: 13px; }

.text-size2 {
  font-weight: bold;
  font-size: 16px; }

.text-size3 {
  font-weight: bold;
  font-size: 18px; }

.text-size4 {
  font-weight: bold;
  font-size: 26px; }

.text-size5 {
  font-weight: bold;
  font-size: 32px; }

@media screen and (min-width: 1900px) {
  .breadcrumb {
    margin-bottom: 0.15rem !important;
    padding: 1.3rem 1rem !important; } }

::ng-deep .ngx-datatable .datatable-body .datatable-body-row > div {
  align-items: center; }

::ng-deep .header-table {
  text-align: center !important;
  padding: 8px 0rem !important; }

::ng-deep .cell-table-center {
  text-align: center !important; }

datatable-footer {
  /* border-top: 1px solid #e6e9ee; */
  /* border-left: 1px solid #ccd0d9; */
  min-height: 20px;
  position: absolute;
  bottom: 0;
  z-index: 10;
  background: #fff;
  min-height: 42px; }

datatable-body {
  width: 100%;
  height: calc(100vh - 350px - 15px) !important; }

datatable-body.datatable-body {
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  border-right: 1px solid #ccd0d9;
  border-left: 1px solid #ccd0d9;
  padding-bottom: 50px; }

::ng-deep .action-group {
  text-align: center !important;
  display: inline-table !important; }

::ng-deep .action-btn {
  padding: 0.3rem 1rem !important;
  font-size: 0.5rem !important; }

.add-data-btn {
  padding: 0.5rem 1rem !important;
  font-size: .20px; }

.md-form {
  margin-top: 0px !important;
  margin-bottom: 0px !important; }

.md-form label {
  font-size: 16px !important;
  font-family: "Sarabun" !important; }

.search-input {
  padding: 0.05rem 0.625rem !important;
  margin: 0px !important; }

::ng-deep .ngx-datatable.material .datatable-header .datatable-header-cell {
  text-align: center !important;
  padding: 0.3rem 0.2rem !important;
  font-weight: 400;
  background-color: #0084b4 !important; }

::ng-deep .ngx-datatable .datatable-footer {
  overflow: inherit !important; }

mdb-select * {
  font-family: "Sarabun" !important;
  font-size: 16px; }

mdb-select-dropdown .dropdown-content li > a, mdb-select-dropdown .dropdown-content li > span {
  font-size: 16px !important; }

::ng-deep .ngx-datatable .datatable-body .datatable-body-row > div {
  align-items: center; }

::ng-deep .cell-table-center {
  text-align: center !important; }

::ng-deep datatable-footer {
  /* border-top: 1px solid #e6e9ee; */
  /* border-left: 1px solid #ccd0d9; */
  min-height: 20px;
  position: absolute;
  bottom: 0;
  z-index: 10;
  background: #fff;
  min-height: 42px; }

datatable-body.datatable-body {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  border-right: 1px solid #ccd0d9;
  border-left: 1px solid #ccd0d9; }

.action-group {
  text-align: center !important;
  display: inline-table !important; }

.action-btn {
  padding: 0.3rem 1rem !important;
  font-size: 0.5rem !important; }

.ngx-datatable.material .datatable-header .datatable-header-cell {
  text-align: center !important;
  padding: 0.3rem 0.2rem !important;
  font-weight: 400;
  background-color: #0084b4 !important; }

.ngx-datatable .datatable-footer {
  overflow: inherit !important; }

datatable-scroller {
  width: 100% !important; }

.datatable-header-cell {
  display: flex !important;
  align-items: center !important; }

mdb-error {
  display: flex !important;
  position: inherit !important;
  font-size: 0.6rem !important; }

.datatable-header-cell-template-wrap {
  margin: auto; }

.cell-center {
  text-align: center !important; }

ngx-datatable {
  overflow-x: scroll !important; }

.datatable-header {
  width: max-content !important; }

.datatable-body {
  width: max-content !important; }

.popover {
  font-family: "Sarabun" !important;
  font-size: 15px !important; }

.popover-header {
  font-weight: bold !important;
  color: red !important;
  font-size: 15px !important;
  background-color: #fb3 !important; }

.text-popup {
  color: red !important; }

.md-form.md-outline label {
  font-size: 16px !important; }

.custom-select {
  font-size: 16px !important;
  font-family: "Sarabun"; }

.form-control-sm {
  font-size: 16px !important;
  font-family: "Sarabun"; }

.md-form.md-outline.form-sm .form-control.form-control-sm {
  height: 40px; }

.datatable-checkbox input {
  opacity: 100 !important; }

.btn_search {
  padding: 0.4rem 0.8rem !important; }

.edit_btn {
  padding: 0.3rem 0.8rem !important;
  font-size: 0.5rem !important; }

.delete_btn {
  padding: 0.3rem 1rem !important;
  font-size: 0.5rem !important; }

datatable-pager > ul > li > a {
  background-color: #69a9ff !important;
  border-color: #69a9ff !important;
  color: #fff !important; }

.ngx-datatable.material .datatable-footer .datatable-pager li.active a {
  background-color: #007bff !important;
  font-weight: bold; }

mdb-select * {
  color: #495057; }

.md-form mdb-select label.active {
  transform: translateY(-22px) scale(0.8) !important; }

datatable-footer {
  width: -webkit-fill-available !important;
  position: inherit !important; }

.owl-dt-container {
  font-size: 16px !important; }

.not-allow-pointer {
  cursor: not-allowed !important;
  pointer-events: all !important; }

.btn_edit_ticket {
  width: 100% !important;
  padding: 6px 10px !important;
  font-size: 12px !important; }

.status_all_ticket {
  width: 100%;
  margin-top: 9px;
  line-height: 1.9; }

.status_ticket {
  width: 100%;
  line-height: 1.9; }

.modal-import .modal-header {
  background-color: #59698d !important; }

.fa, .fas {
  font-family: "Font Awesome 5 Free" !important; }

.far {
  font-family: "Font Awesome 5 Free" !important; }

.custom-modalbox mat-dialog-container {
  padding: 0; }

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #4286f4;
  background-color: #663399; }

.mat-radio-button.mat-accent .mat-radio-inner-circle {
  color: #4286f4;
  background-color: white; }
