$blue:    #263db5 !default;
$indigo:  #6610f2 !default;
$purple:  #663399 !default;
$pink:    #e83e8c !default;
$red:     #d22346 !default;
$orange:  #e97d23 !default;
$yellow:  #FFC107 !default;
$green:   #4CAF50 !default;
$teal:    #20c997 !default;
$cyan:    #9C27B0 !default;

$primary-base: $purple;

$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #eee !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #bbb !default;
$gray-600: lighten(desaturate($primary-base, 40), 4%) !default;
$gray-700: darken(desaturate($primary-base, 40), 0%) !default;
$gray-800: darken(desaturate($primary-base, 40), 8%) !default;
$gray-900: darken(desaturate($primary-base, 40), 12%) !default;
$black:    darken($primary-base, 60%) !default;

$primary:       $primary-base !default;
$secondary:     $gray-800 !default;
$success:       $green !default;
$info:          $blue !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-500 !default;
$dark:          $gray-900 !default;
$background:    $white !default;
$foreground:    $gray-900 !default;
$heading:       darken($foreground, 8%);

$colors: (
  blue: $blue,
  indigo: $indigo,
  purple: $purple,
  pink: $pink,
  red: $red,
  orange: $orange,
  yellow: $yellow,
  green: $green,
  teal: $teal,
  cyan: $cyan,
  white: $white,
  gray: $gray-600,
  gray-dark: $gray-800
) !default;

$theme-colors: (
  primary: $primary,
  secondary: $secondary,
  success: $success,
  info: $info,
  warning: $warning,
  danger: $danger,
  light: $light,
  gray-100: $gray-100,
  gray-200: $gray-200,
  gray-300: $gray-300,
  gray-400: $gray-400,
  dark: $dark
) !default;