$font-family-heading: 'Nunito', sans-serif;
$font-family-base: 'Nunito', sans-serif;
$font-size-base: .813rem;


// // LAYOUT VARS
// $topbar-height: 80px;
// $topbar-height-mobile: 70px;

// $sidebar-left-width: 120px;
// $sidebar-left-width-mobile: 90px;
// $sidebar-left-secondary-width: 220px;
// $sidebar-left-secondary-width-mobile: 190px;
// $side-clearance: 20px;

// GLOBAL COLORS
$facebook: rgb(55, 101, 201);
$google: rgb(236, 65, 44);
$twitter: rgb(3, 159, 245);

@import '~@angular/material/theming';
    @font-face {
      font-family: 'thsarabunPSK';
      src: local('thsarabunPSK'), url('/assets/styles/app/globals/fonts/thsarabunPSK/THSarabun.ttf') format("truetype");
      font-weight: bold;
      font-style: normal;
      font-size: 16px;
    }

    @font-face {
      font-family: 'THSarabunNew';
      src: url('/assets/styles/app/globals/fonts/thsarabunNew/thsarabunnew-webfont.eot');
      src: url('/assets/styles/app/globals/fonts/thsarabunNew/thsarabunnew-webfont.eot?#iefix') format('embedded-opentype'),
           url('/assets/styles/app/globals/fonts/thsarabunNew/thsarabunnew-webfont.woff') format('woff'),
           url('/assets/styles/app/globals/fonts/thsarabunNew/thsarabunnew-webfont.ttf') format('truetype');
      font-weight: normal;
      font-style: normal;
  
  }
  
  @font-face {
      font-family: 'THSarabunNew';
      src: url('/assets/styles/app/globals/fonts/thsarabunNew/thsarabunnew_bolditalic-webfont.eot');
      src: url('/assets/styles/app/globals/fonts/thsarabunNew/thsarabunnew_bolditalic-webfont.eot?#iefix') format('embedded-opentype'),
           url('/assets/styles/app/globals/fonts/thsarabunNew/thsarabunnew_bolditalic-webfont.woff') format('woff'),
           url('/assets/styles/app/globals/fonts/thsarabunNew/thsarabunnew_bolditalic-webfont.ttf') format('truetype');
      font-weight: bold;
      font-style: italic;
  
  }
  
  @font-face {
      font-family: 'THSarabunNew';
      src: url('/assets/styles/app/globals/fonts/thsarabunNew/thsarabunnew_italic-webfont.eot');
      src: url('/assets/styles/app/globals/fonts/thsarabunNew/thsarabunnew_italic-webfont.eot?#iefix') format('embedded-opentype'),
           url('/assets/styles/app/globals/fonts/thsarabunNew/thsarabunnew_italic-webfont.woff') format('woff'),
           url('/assets/styles/app/globals/fonts/thsarabunNew/thsarabunnew_italic-webfont.ttf') format('truetype');
      font-weight: normal;
      font-style: italic;
  
  }
  
  @font-face {
      font-family: 'THSarabunNew';
      src: url('/assets/styles/app/globals/fonts/thsarabunNew/thsarabunnew_bold-webfont.eot');
      src: url('/assets/styles/app/globals/fonts/thsarabunNew/thsarabunnew_bold-webfont.eot?#iefix') format('embedded-opentype'),
           url('/assets/styles/app/globals/fonts/thsarabunNew/thsarabunnew_bold-webfont.woff') format('woff'),
           url('/assets/styles/app/globals/fonts/thsarabunNew/thsarabunnew_bold-webfont.ttf') format('truetype');
      font-weight: bold;
      font-style: normal;
  
  }

  /*Sarabun*/

  @font-face {
    font-family: 'Sarabun';
    src: url('/assets/styles/app/globals/fonts/sarabun/Sarabun-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
  font-family: 'Sarabun';
  src: url('/assets/styles/app/globals/fonts/sarabun/Sarabun-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Sarabun';
  src: url('/assets/styles/app/globals/fonts/sarabun/Sarabun-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Sarabun';
  src: url('/assets/styles/app/globals/fonts/sarabun/Sarabun-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

/*end Sarabun*/

   $app-typography: mat-typography-config(
      $font-family: 'Sarabun',
      $body-1: mat-typography-level(16px, 1.6, bold)
    );

    @include mat-core($app-typography);