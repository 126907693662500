h1, h2, h3, h4, h5, h6, .card-title, .text-title {
  color: $heading;
}

@for $i from 10 through 78 {
  .text-#{$i} {
    font-size: #{$i}px;
  }
}
@for $i from 300 through 900 {
  .font-weight-#{$i} {
    font-weight: $i;
  }
}

.text-small {
  font-size: .75rem;
}

@import '~@angular/material/theming';
    @font-face {
      font-family: 'thsarabunPSK';
      src: local('thsarabunPSK'), url('/assets/styles/app/globals/fonts//thsarabunPSK/THSarabun.ttf') format("truetype");
      font-weight: bold;
      font-style: normal;
      font-size: 16px;
    }

    @font-face {
      font-family: 'THSarabunNew';
      src: url('/assets/styles/app/globals/fonts/thsarabunNew/thsarabunnew-webfont.eot');
      src: url('/assets/styles/app/globals/fonts/thsarabunNew/thsarabunnew-webfont.eot?#iefix') format('embedded-opentype'),
           url('/assets/styles/app/globals/fonts/thsarabunNew/thsarabunnew-webfont.woff') format('woff'),
           url('/assets/styles/app/globals/fonts/thsarabunNew/thsarabunnew-webfont.ttf') format('truetype');
      font-weight: normal;
      font-style: normal;
  
  }
  
  @font-face {
      font-family: 'THSarabunNew';
      src: url('/assets/styles/app/globals/fonts/thsarabunNew/thsarabunnew_bolditalic-webfont.eot');
      src: url('/assets/styles/app/globals/fonts/thsarabunNew/thsarabunnew_bolditalic-webfont.eot?#iefix') format('embedded-opentype'),
           url('/assets/styles/app/globals/fonts/thsarabunNew/thsarabunnew_bolditalic-webfont.woff') format('woff'),
           url('/assets/styles/app/globals/fonts/thsarabunNew/thsarabunnew_bolditalic-webfont.ttf') format('truetype');
      font-weight: bold;
      font-style: italic;
  
  }
  
  @font-face {
      font-family: 'THSarabunNew';
      src: url('/assets/styles/app/globals/fonts/thsarabunNew/thsarabunnew_italic-webfont.eot');
      src: url('/assets/styles/app/globals/fonts/thsarabunNew/thsarabunnew_italic-webfont.eot?#iefix') format('embedded-opentype'),
           url('/assets/styles/app/globals/fonts/thsarabunNew/thsarabunnew_italic-webfont.woff') format('woff'),
           url('/assets/styles/app/globals/fonts/thsarabunNew/thsarabunnew_italic-webfont.ttf') format('truetype');
      font-weight: normal;
      font-style: italic;
  
  }
  
  @font-face {
      font-family: 'THSarabunNew';
      src: url('/assets/styles/app/globals/fonts/thsarabunNew/thsarabunnew_bold-webfont.eot');
      src: url('/assets/styles/app/globals/fonts/thsarabunNew/thsarabunnew_bold-webfont.eot?#iefix') format('embedded-opentype'),
           url('/assets/styles/app/globals/fonts/thsarabunNew/thsarabunnew_bold-webfont.woff') format('woff'),
           url('/assets/styles/app/globals/fonts/thsarabunNew/thsarabunnew_bold-webfont.ttf') format('truetype');
      font-weight: bold;
      font-style: normal;
  
  }

   /*Sarabun*/

   @font-face {
    font-family: 'Sarabun';
    src: url('/assets/styles/app/globals/fonts/sarabun/Sarabun-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
  font-family: 'Sarabun';
  src: url('/assets/styles/app/globals/fonts/sarabun/Sarabun-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Sarabun';
  src: url('/assets/styles/app/globals/fonts/sarabun/Sarabun-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Sarabun';
  src: url('/assets/styles/app/globals/fonts/sarabun/Sarabun-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

/*end Sarabun*/

   $app-typography: mat-typography-config(
      $font-family: 'Sarabun',
      $body-1: mat-typography-level(16px, 1.6, bold),
      $subheading-1: mat-typography-level(24px, 1.6, bold)
    );

    @include mat-core($app-typography);